.mapWrapper {
  box-sizing: border-box;
  border: 5px solid #0180ea;
  text-align: center;
  width: fit-content;
  margin: auto;
}


@media only screen and (max-width: 1800px) {
  .mapWrapper {
    min-width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .mapWrapper {
    width: 90%;
    border: 3px solid #0180ea;
    height: auto;
  }

  .snow {
    height: "3600px";
  }
}
