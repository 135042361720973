.pageWrapper {
  background: #000;
  border-top: 2px solid #114780;
  border-bottom: 2px solid #114780;
  padding-bottom: 40px;
}

.iconsContainer {
  padding-top: 40px;
  max-width: 85%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.wifi {
  position: relative;
  left: -30px;
}

.singleIconContainer {
  display: flex;
  align-items: center;
}


.singleIconContainer p {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  max-width: 140px;
}

.singleIconContainer img {
  padding-right: 45px;
}

.pageWrapper div:nth-child(2) > div:nth-child(3) {
  position: relative;
  left: -2em;
}

@media only screen and (max-width: 600px) {
  .pageWrapper {
    padding-bottom: 0;
  }

  .pageWrapper div:nth-child(2) > div:nth-child(3) {
    left: 0;
  }

  .pageWrapper {
    padding-top: 1em;
  }

  div.wifi {
    position: static;
  }

  .iconsContainer {
    margin: auto;
    width: 80%;
    padding: 0;
  }

  .singleIconContainer img {
    width: 100%;
    padding-right: 0;
  }


  .singleIconContainer {
    width: 16%;
    flex-direction: column;
    align-items: center;
  }

  .singleIconContainer p {
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
  }
}
