.pageWrapper {
    max-width: 82%;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10px 0 20px 0;
  }

  .signle {
    max-width: 90%;
    margin: auto;
}


  @media only screen and (max-width: 1000px) {
    .pageWrapper {
      max-width: 90%;
    }
  }