.shell {
   background: #20101A;
}

.miniBonuses {
    max-width: 82%;
    margin: auto;
    display : flex;
    justify-content: space-between;
    padding-bottom: 25px;
}


@media only screen and (max-width: 600px) {
    .miniBonuses {
        display: none;
    }
}