.logo {
  max-width: 100%;
}

.logo img {
  width: 70%;
}

.topnav {
  /* font-family: "Balsamiq Sans", cursive; */
  text-transform: uppercase;
  background-color: #190f15;
  width: 100%;
}

.topnav a {
  color: whitesmoke !important;
  font-size: 22px;
}

.topnav a:hover {
  color: cyan !important;
}

.fb:hover {
  color: #4064ac !important;
}

.dropdown-item .nav-link a {
  border-bottom: 2px solid #fff !important;
}

.social {
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.social a {
  color: #4064ac;
}

.pageWrapper {
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;
}

.decoration-lines {
  background-color: #190f15;
  padding-bottom: 3px;
}

.decoration-lines span {
  height: 2px;
  width: 100%;
  display: block;
  margin-bottom: 4px;
}

.decoration-lines span:first-child {
  background-color: #0093ff;
}

.decoration-lines span:nth-child(2) {
  background-color: #004cdd;
}

.decoration-lines span:nth-child(3) {
  background-color: #3b289b;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4caf50;
  color: white;
}

.topnav-right {
  float: right;
}


@media only screen and (max-width: 1600px) { 
  .logo img {
    max-width: 80%;
  }

  .topnav a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  nav.topnav {
    display: flex;
  }

  .social {
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  .pageWrapper {
    padding: 0;
    max-width: 100%;
    justify-content: space-around;
  }

  .logo {
    max-width: 100%;
  }

  .logo img {
    width: 100%;
  }
  
  .mobileNavbar {
    margin-right: 1em;
  }
}
