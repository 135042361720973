.pageWrapper {
    max-width: 80%;
    margin: auto;
    padding: 10px 0 20px 0;
  }

  .bonusWrapper {
    border: 2px solid #114780;
    background: #100e0f;
  }

  .imgContainer {
    width: 100%;
    padding: 20px 20px 10px 20px;
    height: 450px;
  }

  .imgContainer img {
    border: 2px solid #114780;
    width: 100%;
    height: 100%;
  }

  .subTitle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 22px;
  }

  .bonusWrapper p {
    color: #0180ea;
    padding: 0 20px;
  }

  .title {
    text-align: center;
    padding-bottom: 2em;
  }

  .title h1 {
    color: whitesmoke;
    font-size: 34px;
    margin:0;
  }

  .rules p {
    font-size: 18px;
    color: whitesmoke;
  }

  .icon {
    width: 3%;
    vertical-align: middle;
    padding-right: 8px;
  }

  .articleActions {
    display: flex;
    justify-content: space-between;
    padding: 0 25px 20px 20px;
  }

  .customButton button {
    width: 130%;
    border: 1px solid #0180ea;
  }

  @media only screen and (max-width: 1000px) { 
    .pageWrapper {
      max-width: 95%;
    }

    .imgContainer {
      height: 220px;
    }

    .bonusWrapper p {
      padding: 0;
    }

    .subTitle {
      text-align: center;
      padding: 0 10px;
    }

    .title,
    .rules p {
      padding-left: 20px;
      padding-right: 20px;
    }

    .icon {
      width: 12.5%;
    }

    .customButton button {
      width: 93%;
    }
  }