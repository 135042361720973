.sectionIntro {
  background: #20101a;
  padding-top: 35px;
}

.googleContainer {
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.googleData {
  margin-top: 20px;
  width: 100%;
  padding: 40px 0;
  border-bottom: 2px solid #114780;
  border-top: 2px solid #114780;
}

.googleMap {
  border: 5px solid #0180ea;
  height: 503px;
}

.miniBonuses {
  padding-top:20px;
  max-width: 82%;
  margin: auto;
  display : flex;
  justify-content: space-between;
  padding-bottom: 25px;
}


@media only screen and (max-width: 600px) {

  .miniBonuses {
    flex-flow: column nowrap;
    max-width: 90%;
  }

  .googleContainer {
    flex-direction: column;
    max-width: 90%;
  }

  .googleMap {
    height: auto;
    border: none;
  }
}