@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

.imgContainer {
  padding: 18px;
  height: 65%;
  width: 100%;
}

.img {
  border: 2px solid #114780;
  width: 100%;
  height: 100%;
}

.container {
  display: inline-block;
  border: 2px solid #114780;
  background: #100e0f;
  vertical-align: top;
  margin: 12px;
  text-align: center;
  max-width: 520px;
  height: 520px;
}

.title:hover {
  background: blue;
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 0 6px 0;
  border-bottom: 1px solid #2b415433;
  color: #fff;
}

.address {
  display: block;
  font-size: 18px;
  color: #fff;
  padding-bottom: 1em;
}

.customButton {
  max-width: 80%;
  margin: 5px auto;
}

@media only screen and (max-width: 1400px) {

.container {
  max-width: 350px;
  max-height: 470px;
}

}

@media only screen and (max-width: 600px) {
  .container {
    width: auto;
    height: auto;
    border-radius: 8px;
  }

  .customButton button {
    border: 1px solid #0180ea;
  }

  .customButton {
    padding: 8px;
  }
}
