.button {
  position: absolute;
  bottom: 1em;
  left: 13em;
}

.button a {
  border-radius: 8px;
  display: inline-block;
  padding: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  transition: 0.2s;
  border: 1px solid #fff;
  color: #fff;
}


.button a:hover {
  color: whitesmoke;
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  border: 2px solid #0180ea;
}

.imageWrapper {
  max-width: 740px;
  height: 500px;
}

.container {
  position: relative;
}


@media only screen and (max-width: 600px) {
  .imageWrapper {
    max-width: auto;
    height: auto;
  }

  .bonus {
    left: 5em;
  }

  .button {
    left: 5em;
  }

  .button a {
    padding: 5px 15px;
    font-size: 12px;
    letter-spacing: 1px;
    overflow: hidden;
  }

  .container {
    margin-bottom: 1.5em;
  }
}