.footer {
  background: #000;
  padding-top: 15px;
}

.footerWrapper {
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.footerWrapper p {
  color: #fff;
  font-size: 20px;
}

.footerWrapper a {
  color: #0180ea;
  text-decoration: none;
}

.footerWrapper a:hover {
  color: #39a3fa;
}

@media only screen and (max-width: 600px) {
  .footerWrapper {
    max-width: 90%;
    flex-flow: column nowrap;
    align-items: center;
  }

  .support {
    display: block;
    text-align: center;
  }

  .support p {
    max-width: 80%;
    margin: 0 auto 20px;
  }
}
