.componentWrapper {
  border: 1px solid #0181ec;
  max-width: 80%;
  margin: auto;
  color: #fff;
  background: #100e0f;
  display: flex;
  align-items: center;
}

.articleImage img {
  width: 100%;
}

.articleImage {
  max-width: 500px;
  border: 1px solid #0181ec;
  margin: 20px;
}

.articleWrapper {
  vertical-align: top;
}

.articleWrapper,
.articleImage {
  display: inline-block;
}

.casinoPresentation {
  max-width: 960px;
  font-size: 18px;
  margin-bottom: 2.5em;
}

.navigation {
  border-bottom: 1px solid #0181ec;
  margin-bottom: 2em;
  padding-bottom: 1em;
}

.titles h2 {
  margin-bottom: 0;
  color: #f9f9f9;
  font-size: 36px;
  font-weight: 100;
}

.titles h3 {
  margin: 0;
  color: #f9f9f9;
  font-size: 24px;
  font-weight: 100;
}

.facebook a {
  color: #fff;
}

.facebook a:hover {
  color: #1877f2;
  border-bottom: none;
}

.articleActions,
.navigation {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.articleActions {
  align-items: flex-end;
}


.customButton button {
  width: auto;
  border: 1px solid #0180ea;
}

@media only screen and (max-width: 1850px) { 
  .articleImage {
    margin-right: 0;
  }

  .articleWrapper {
    padding: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .componentWrapper {
    flex-flow: column nowrap;
  }
 }

@media only screen and (max-width: 600px) {
  .componentWrapper {
    min-width: 90%;
    display: flex;
    flex-flow: column wrap;
  }

  .articleImage {
    margin: 15px;
  }

  .navigation {
    padding: 0 15px 15px;
  }

  .casinoPresentation {
    padding: 0 15px;
  }

  .articleActions {
    padding-bottom: 1em;
  }
}

@media only screen and (max-width: 600px) {
  .componentWrapper {
    margin-bottom: 1.5em;
  }
}