.galleryContainer {
  max-width: 80%;
  margin: 50px auto;
  border: 2px solid #114780;
}

@media only screen and (max-width: 600px) {
  div.galleryContainer {
    max-width: 90%;
  }

  div.galleryContainer svg {
    display: none;
  }
}
