.pageWrapper {

  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 0;
}

@media only screen and (min-width: 1400px) {
.container {
  max-width: 85%;
  margin: auto;
}

}

@media only screen and (max-width: 1400px) {
  
.pageWrapper {
  display: flex;
  justify-content: center;
}
  
  }

@media only screen and (max-width: 600px) {
  .pageWrapper {
    max-width: 100%;
  }

  .snow {
    height: 185vh;
  }
}
