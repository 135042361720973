@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

.imgContainer {
  padding: 18px;
  height: 64%;
  width: 100%;
}

.img {
  border: 2px solid #114780;
  width: 100%;
  height: 100%;
}

.bonus {
  display: inline-block;
  border: 2px solid #114780;
  background: #100e0f;
  vertical-align: top;
  margin: 12px;
  text-align: center;
  width: 48%;
  height: 470px;
}

.title:hover {
  background: blue;
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 0 6px 0;
  border-bottom: 1px solid #2b415433;
  color: #fff;
}

.address {
  display: block;
  font-size: 18px;
  color: #0180ea;
  padding-bottom: 1em;
}

.customButton button {
  width: 135%;
  border: 1px solid #0180ea;
}

.date {
  color: #0180ea;
  text-decoration: underline;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

@media only screen and (max-width: 1400px) {
  .bonus {
    width: 47%;
    height: 400px;
  }

  .imgContainer {
    height: 60%;
  }
  .customButton {
    padding-right: 15px;
  }

  .title {
    text-align: center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 800px) {
  .customButton {
    padding-right: 0;
  }


  .imgContainer {
    width: auto;
    height: 100%;
  }

  .bonus {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    margin: 0 0 25px;
  }

  .actions {
    flex-direction: column;
    justify-content: center;
    margin: 0 2.5em 2em 0;
  }
}