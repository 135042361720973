.about h2,
.contactsInfo h2 {
  margin: 0;
  color: #f9f9f9;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 100;
}

.about {
  max-width: 78%;
  padding-top: 40px;
  margin: 0 auto 30px;
}

.about h2 {
  padding-bottom: 0.5em;
}

.about article {
  max-width: 920px;
  color: #ebecec;
}

.contacts {
  display: flex;
  justify-content: space-between;
  max-width: 78%;
  margin: auto;
  background: #000;
  border: 2px solid #114780;
  padding: 20px 20px 20px 25px;
}


.iconArticle {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.phones {
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  font-size: 24px;
  padding-left: 1em;
}

.phones p {
  font-size: 20px;
}

.phones a {
  text-decoration: none;
}

.phonesWorkingTimeMobile {
  display: none;
}

.address {
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  font-size: 18px;
}


.address a {
  color: #0180ea;
}

.address a:hover,
.email a:hover,
.phones a:hover {
  color: #61dafb;
}

.email a,
.phones a {
  font-size: 24px;
}

.address,
.email a {
  padding-left: 1em;
}

.title {
  text-align: center;
  padding-bottom: 2em;
}

.phonesEmail svg {
  width: fit-content !important;
  height: 38px;
}

@media only screen and (max-width: 1800px) {
  .contacts {
    align-items: center;
  }

  .mapWrapper {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1200px) {

  .phonesWorkingTime {
    display: none;
  }

  .phonesWorkingTimeMobile {
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    font-size: 15px;
    padding-left: 1em;
  }

  .phonesWorkingTimeMobile p {
    text-align: center;
    margin:0;
  }

  .contacts {
    align-items: center;
  }

  .phonesEmail a {
    font-size: 18px;
  }

  .address p,
  .address a {
    font-size: 15px;
  }

  .about article {
    padding: 0 1.5em;
  }

  .contacts {
    flex-flow: column wrap;
    max-width: 100%;
    padding-left: 15px;
  }

  .contactsInfo {
    margin-bottom: 3em;
  }

  .mapWrapper {
    height: 100%;
    width: 100%;
    margin: auto;
    border: 3px solid #0180ea;
  }

.iconArticle svg {
    height: 35px !important;
  }

  .about {
    max-width: 100%;
  }
}

