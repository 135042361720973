.buttonWrapper {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  background: transparent;
  font-family: "consolas";
}

.button {
  margin: 0;
  padding: 0;
  max-width: 80%;
  background: #100f;
  text-decoration: none;
}

.glow-on-hover {
  width: 400px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  border: 1px solid #0180ea;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}


@media only screen and (max-width: 1400px) {
  .glow-on-hover {
    width: auto;
    text-align: center;
    text-transform: uppercase;
  }

  .button {
    max-width: auto;
    text-align: center;
  }

  .buttonWrapper {
    justify-content: space-around;
  }
}
